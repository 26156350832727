<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2483 6.35159C15.4733 6.57662 15.5996 6.88179 15.5996 7.19999C15.5996 7.51818 15.4733 7.82335 15.2483 8.04839L11.2967 12L15.2483 15.9516C15.4669 16.1779 15.5878 16.481 15.5851 16.7957C15.5824 17.1103 15.4562 17.4113 15.2337 17.6338C15.0112 17.8563 14.7102 17.9825 14.3956 17.9852C14.0809 17.9879 13.7778 17.867 13.5515 17.6484L8.75149 12.8484C8.52653 12.6234 8.40015 12.3182 8.40015 12C8.40015 11.6818 8.52653 11.3766 8.75149 11.1516L13.5515 6.35159C13.7765 6.12662 14.0817 6.00024 14.3999 6.00024C14.7181 6.00024 15.0233 6.12662 15.2483 6.35159Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconChevronLeft",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
